.sidebar-container{

  opacity:0.95 !important;
  margin-top: 90px;
  
  /*height: 54em;*/
}
.list-group-item-general {
  position: relative;
  display: block;
  padding: .2rem 0.75rem;
  background-color: #fff;
  border: 2px solid rgba(19, 136, 3, 0.125);
  width: 100%;

}


.list-group-item-new {
  position: relative;
  display: block;
  /*padding: .2rem 0.75rem;*/
  background-color: #fff;
  /*border: 1px solid rgba(0,0,0,.125);*/
  width: 100%;
  padding: 0px;
  border: 0px solid rgba(0,0,0,.125);
  /*text-overflow: ellipsis; hidde text if is too long*/
margin-top: 5px;
  overflow: auto; /*auto: el div es scroliable*/

}
.list-group-item-new:hover { background-color: #ececec; }
.list-group-item-new:focus { background-color: #ececec; }

.list-group-item-item{
  padding: 0px 0px;
  border: 1px solid rgba(0,0,0,.125);
  background-color: #fff;
}
 .list-group-item-item:hover { background-color: #ececec; }
 .list-group-item-item:focus { background-color: #ececec; }


.list-group-item-item-tools{
  padding: 5px 5px;
  border: 0px solid rgba(0,0,0,.125);
  background-color: #fff;

}
.list-group-item-item-tools-opacity{
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  -webkit-font-smoothing: antialiased;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  -webkit-text-size-adjust: 100%;
  visibility: visible;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  cursor: grab;
  box-sizing: border-box;
  padding: 5px 5px;
  border: 0px solid rgba(0,0,0,.125);
  background-color: #fff;
  width: 30%;
}

.range-slider{
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  visibility: visible;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  box-sizing: border-box;
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 20%;
}

.list-group-item-item-tools:hover { background-color: #ececec; }
.list-group-item-item-tools:focus { background-color: #ececec; }

.list-group-item-legend{
  width: 100%;

}
.div-scroll {
  overflow-x: hidden;
  overflow-y: auto;
}

.content-column {

  /*overflow: scroll;*/

}
.characters list-group list-group-flush{
--bs-blue: #0d6efd;
--bs-indigo: #6610f2;
--bs-purple: #6f42c1;
--bs-pink: #d63384;
--bs-red: #dc3545;
--bs-orange: #fd7e14;
--bs-yellow: #ffc107;
--bs-green: #198754;
--bs-teal: #20c997;
--bs-cyan: #0dcaf0;
--bs-white: #fff;
--bs-gray: #6c757d;
--bs-gray-dark: #343a40;
--bs-primary: #0d6efd;
--bs-secondary: #6c757d;
--bs-success: #198754;
--bs-info: #0dcaf0;
--bs-warning: #ffc107;
--bs-danger: #dc3545;
--bs-light: #f8f9fa;
--bs-dark: #212529;
--bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
--bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
-webkit-font-smoothing: antialiased;
font-family: var(--bs-font-sans-serif);
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: transparent;
visibility: visible;
box-sizing: border-box;
display: flex;
flex-direction: column;
padding-left: 0;
margin-bottom: 0;
border-radius: 0;
overflow-anchor: none;
}