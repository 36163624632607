.calendar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }

  .floating-calendar-container {
    position: relative;
  }
  
  .calendar-popup {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 5px;
  }
  