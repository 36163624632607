.show-doc-box{

    --bs-blue: #0d6efd;
--bs-indigo: #6610f2;
--bs-purple: #6f42c1;
--bs-pink: #d63384;
--bs-red: #dc3545;
--bs-orange: #fd7e14;
--bs-yellow: #ffc107;
--bs-green: #198754;
--bs-teal: #20c997;
--bs-cyan: #0dcaf0;
--bs-white: #fff;
--bs-gray: #6c757d;
--bs-gray-dark: #343a40;
--bs-primary: #0d6efd;
--bs-secondary: #6c757d;
--bs-success: #198754;
--bs-info: #0dcaf0;
--bs-warning: #ffc107;
--bs-danger: #dc3545;
--bs-light: #f8f9fa;
--bs-dark: #212529;
--bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
--bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
--bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
-webkit-font-smoothing: antialiased;
font-family: var(--bs-font-sans-serif);
font-size: 1rem;
font-weight: 400;
line-height: 1.5;
color: #212529;
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: transparent;
box-sizing: border-box;
position: fixed;
bottom: 0;
z-index: 1050;
display: flex;
flex-direction: column;
max-width: 100%;
background-color: #fff;
background-clip: padding-box;
outline: 0;
transition: transform .3s ease-in-out;
top: 0;
right: 0;
width: 600px;
border-left: 1px solid rgba(0,0,0,.2);
transform: none;
visibility: visible;
margin-top: 90px;
transform: none;

}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 600px;
    border-left: 1px solid rgba(0,0,0,.2);
    /*margin-top: 75px;
    margin-bottom: 190px;*/
}
