.tooltip-container {
    position: relative;
    display: inline-block;
  }
  
  .tooltipnew {
    position: absolute;
    left: 0;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ccc;
    z-index: 1;
    font-size:.875rem;
    color: white;
    background-color: #969393;
    width: 15rem;
    border-radius: 10px 2% / 0px 25em 30px 35em;

  }
 .tooltipnew:before{
    position: absolute;
    content: "\f0d8";
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    top: -8px;
    transform: rotate(76deg);
    color: #4692F0;
    font-size: 35px;
    left: 25px;
}

  
  .tooltipnew a {
    display: block;
    margin-bottom: 5px;
    color: #333;
    text-decoration: none;
  }
  
  .tooltipnew a:hover {
    text-decoration: underline;
  }

  div.box {
    position: absolute;
    left: 0;
    background-color: #f9f9f9;
    padding: 10px;
    border: 1px solid #ccc;
    z-index: 1;
    font-size:.875rem;
    width: 9rem;
    border-color: green;
    border-radius: 6px;


}

  div.box-arrow-border {
    position: absolute;
    top: -9px; left: 5px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid black;
    width: 0; height: 0;

}

div.box-arrow-background {
    position: absolute;
    left: -10px; top: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid white;
    width: 0; height: 0;

}
div.box-content {
    padding: 2px;  /* <--------------------------------------- optional */
    width: ;
}

.boton-descargando {
  background-color: #ccc;
  cursor: not-allowed;
}

