.list-group-layername-item-item{
  padding: 0px 0px;
  border: 0px solid rgba(0,0,0,.125);
  background-color: #fff;
  /*height: 20%;*/
  max-width: 100%;
}

.list-group-tools{
/*float: right;*/
}

.list-group-layername {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 0.25rem;
    /*/padding-right: 0;*/
  }

div.tooltip-inner {
    text-align: left;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    border-radius: 0px;
    margin-bottom: 0px;
    background-color: #c7f5a1;
    font-size: 14px;
    max-width: 250px !important;
    color: black;
    border-radius: 5px;
    white-space: pre-line; /* Agregar esta línea para hacer saltos de línea */
    font-family: var(--bs-font-sans-serif);

}

.container-text2{
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  height: 100%;
}

.container-item-text {

    border: 1px solid #0e12c9;
    text-align: justify;
    border-radius: 5px 5px 5px 5px;
    -moz-border-radius: 5px 5px 5px 5px;
    -webkit-border-radius: 5px 5px 5px 5px;
}
.layer-name{
  max-width: 100%;
  /*padding-top: 0px;
  padding-bottom: 0px;*/
  margin-top: 0px;
  margin-bottom : 0px;
  /*font-size:medium;*/
  padding: 0px;
  border: 0px solid rgba(0,0,0,.125);



}
.layer-name:hover { background-color: #ececec; }
.layer-name:focus { background-color: #ececec; }

.card-body2{
  flex: 1 1 auto;
  padding: 1rem 1rem;
  height: 100%;
  /*position: relative;*/
  box-sizing:content-box;
  opacity:0.9 !important;
  overflow: auto; /*auto: el div es scroliable*/

}