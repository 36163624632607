.leaflet-topp {
    bottom: 0;
    }

.leaflet-topp .leaflet-control-zoomm {
    top:40%;
    transform: translateY(-50%);
    }
/*este no lo uso mas*/
.leaflet-verticalcenter {
        position: absolute;
        z-index: 1000;
        pointer-events: none;
        top: 50%; /* possible because the placeholder's parent is the map */
        transform: translateY(-50%); /* using the CSS3 Transform technique */
        padding-top: 10px;
        left: 97%;
        right: 3%;
        margin-right: 10px;
    }
/*este no lo uso mas*/
.leaflet-verticalcenter .leaflet-control {
        margin-bottom: 10px;
    }


.MapControls_wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 4000;
    display: flex;
    flex-direction: column;
    transition: all .5s ease;
    -webkit-transform: translate3d(410px,-109px,0);
    transform: translate3d(410px,-109px,0);
}


.btn-custom {
    color: #fff;
    background-color: rgb(189, 10, 10) ;
    border-color: #fff;
}

.btn-custom:hover
    { background-color: #ffdb3a !important; }



/*para agregar otras posiiciones a las default*/
.leaflet-center {
    left: 50%;
    transform: translate(-50%, 0%);
}

.leaflet-middle {
    top: 50%;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
  transform: translate(0%, -50%);

}

.leaflet-center.leaflet-middle {
  transform: translate(-50%, -50%);
}

/*Aplicada al icono de info. Centra a la derecha.*/

.leaflet-middle-right {

-webkit-font-smoothing: antialiased;
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: transparent;
font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
box-sizing: border-box;
position: relative;
z-index: 800;
pointer-events: auto;
clear: both;
cursor: auto;
border-radius: 4px;
float: right;
margin-bottom: 10px;
margin-right: 10px;
box-shadow: none;
background-clip: padding-box;
top: 50%; /* possible because the placeholder's parent is the map */
background-color: #fff;
border-color: black;

}
.leaflet-middle-right:hover
    {
        background-color: #f8f9fa !important;
    }



/*aplicado a busqueda por coordenadas*/

.leaflet-top-right-disp-left {

    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    position: relative;
    z-index: 800;
    pointer-events: auto;
    clear: both;
    cursor: auto;
    border-radius: 4px;
    float: right;
    margin-top: 10px;
    margin-right: 10px;
    box-shadow: none;
    background-clip: padding-box;
    top: 1%; /* possible because the placeholder's parent is the map */
    background-color: #fff;
    border-color: black;

    }

.leaflet-top-right-disp-left-home {

        -webkit-font-smoothing: antialiased;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
        box-sizing: border-box;
        position: relative;
        z-index: 800;
        pointer-events: auto;
        clear: both;
        cursor: auto;
        border-radius: 4px;
        float: right;
        margin-top: 120px;
        margin-right: 10px;
        box-shadow: none;
        background-clip: padding-box;
        top: 1%; /* possible because the placeholder's parent is the map */
        background-color: #fff;
        border-color: black;
    
        }
    
    
.leaflet-top-leaflet-right{

    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    -webkit-font-smoothing: antialiased;
    color: #212529;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    cursor: grab;
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    right: 0;
    background-color: #fff;
    margin-right: 10px;
    outline-color: #198754;
    outline-width: 10px;


}

.leaflet-top-leaflet-right-down{

    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    -webkit-font-smoothing: antialiased;
    color: #292124;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    cursor: grab;
    font: 12px/1.5 "Helvetica Neue", Arial, Helvetica, sans-serif;
    box-sizing: border-box;
    position: absolute;
    z-index: 1000;
    pointer-events: none;
    top: 0;
    right: 0;
    background-color: #fff;
    margin-right: 10px;
    margin-top: 5em;
    outline-color: #198754;
    outline-width: 10px;


}

