
.MuiAppBar-root {
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
}
 .MuiSlider-root {

  }

  .MuiSlider-root:hover {
    color: #2e8b57;
  }

  .MuiToolbar-root{
    color: #b22069;
  }


  .MuiToolbar-gutters{
    color: #20b231;
  }

  .MuiToolbar-dense {
    color: #ffffff;
  }
  /*controla color del texto sobre la barra*/
  .css-10ltm04-MuiToolbar-root{
    color: #ffffff;
    size:0.5em;
    max-height: 90px;
  }


  .responsive {
    width: 100%;
    max-width: 50px;
    height: auto;
    }

  .MuiAppBar-positionStatic {
      position: static;
  }


  .MuiAppBar-colorPrimary {
    color: #fff;
    background-color: #37bbed;
  }

  .MuiPaper-elevation4 {
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
}
  .MuiPaper-root .MuiAppBar-root .MuiAppBar-positionStatic .MuiAppBar-colorPrimary .MuiPaper-elevation4{
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-primary: #0d6efd;
    --bs-secondary: #6c757d;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-font-sans-serif: system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    -webkit-font-smoothing: antialiased;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12);
    width: 100%;
    display: flex;
    z-index: 1100;
    box-sizing: border-box;
    flex-shrink: 0;
    flex-direction: column;
    position: static;
    color: #fff;
    background-color: #37bbed;
  }

.custom-spinner-border{

display: inline-block;
  width: 4rem;
  height: 4rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: .75s linear infinite spinner-border;
  justify-content: center!important;


}