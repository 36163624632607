.collapsible-div {
    border: 1px solid #ccc;
    margin: 10px;
    border-radius: 4px;
    overflow: hidden;
    
  }
  
  .collapsible-header {
    background-color: #f1f1f1;
    padding: 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }
  
  .collapsible-header:hover {
    background-color: #ddd;
  }
  
  .arrow {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 5px 0 5px;
    border-color: #555 transparent transparent transparent;
    margin-right: 5px;
    transform: rotate(0deg);
    transition: transform 0.3s;
  }
  
  .arrow.open {
    transform: rotate(180deg);
  }
  
  .collapsible-content {
    padding: 10px;
    background-color: #fff;
  }
  