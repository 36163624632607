/* styles.css */

.styled-table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 14px;
  }
  
  .styled-table th,
  .styled-table td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  .styled-table th {
    background-color: #f2f2f2;
  }
  
  .styled-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .styled-table tbody tr:hover {
    background-color: #ddd;
  }
  