/*.layer-modal-content div {
    & div, & h1, & h2, & h3, & h4, & p, & span {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      color: #383838;
    }
  }
*/

.layer-modal-content h1,
.layer-modal-content h2,
.layer-modal-content h3,
.layer-modal-content h4,
.layer-modal-content p,
.layer-modal-content span {
font-family: Open Sans,sans-serif;
font-weight: 300;
color: #383838;
}

.form-inline {
    width: 100%;
  }

  .form-group {
    width: 100%;
  }

  .input-group {
    width: 100% !important;
  }

  .form-control {
    width: 100% !important;
  }

  span.input-group-addon {
    width: 100px !important;
  }
/*controla el color de fondo de un botton al presionarlo*/
  .Btn-Blue-BG:hover  {
    background-color: blue !important;
  }
  .Btn-Blue-BG:focus  {
    background-color: blue !important;
  }

.modal-container
  {
      opacity:0.9 !important;
  }
  .modal-container-stats
  {
      opacity:0 !important;
  }
/*controla las cajas div de los geoservicios*/
.layer-category {
    cursor: pointer;
    /*background-color: #bbbddd;*/
    width: 100%;/*310px;*/
    height: 230px;
    /*overflow-x: hidden;*/ /*auto: el div es scroliable*/
    /*overflow-y: auto;*/
    margin-bottom: 10px;
    text-overflow: ellipsis; /*hidde text if is too long*/

}
/*controla los div de cada nombre de capa*/
.layer-category-name {
  display: block;
  padding: 7px 10px;
  width: 100%;
  /*color: #fff;*/
  white-space: nowrap;
  text-overflow: ellipsis; /*hidde text if is too long*/
  /*overflow-x: hidden; //auto: el div es scroliable
  overflow-y: hidden;*/
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  padding-right:15px;

}
/*oculta el texto que sobre sale del div, y al pasar el mouse lo expande*/
.layer-category-name:hover {
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  text-align: left;

}
.layer-category-name:focus {
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
  text-align: left;

}


.layer-metadatos {
    cursor: pointer;
    color: rgb(153, 23, 23);
    /*background-color: #c6c6cf;*/
    width: 100%;/*310px;*/
    height: 230px;
    /*margin-bottom: 10px;*/
    text-overflow: ellipsis; /*hidde text if is too long*/
    /*white-space: normal;*/
    text-align: justify;
    padding: 0.1rem;

}

.layer-metadatos-text {
   width: 100%;/*310px;*/
   text-overflow: ellipsis; /*hidde text if is too long*/
   text-align: justify;
   margin-left: 10px;
   /*overflow-x: hidden; *//*auto: el div es scroliable*/
   /*overflow-y: auto;*/
   overflow-wrap: break-word;

}
.card-header-fixed {

  position: 'fixed';

}


.layer-metadatos br {
  display: inline;
}

.category-masonry-case {
    overflow: hidden;
    margin: auto;
    max-width: 960px;
  }


  @media (min-width: 768px) {
  .container {
      max-width: 720px;
  }
}

  @media (min-width: 576px) {
  .container {
      max-width: 540px;
  }
}
  .container {
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
  }



/*
  .custom-layer-dialog {
    margin: 5px auto;
    border: none;

    & .modal-content {
      height: 100%;
    }
    &.category-width {
      width: 978px;
      max-width: 95%;
      & .modal-body {
        padding: 0 0 0 10px;
        overflow-y: auto;
      }
      .product-outer-list-case {
        padding: 0 13px 13px 0;
      }
    }
    &.browse-search-width {
      width: 98%;
      max-width: 1200px;
      & .layer-modal-content {
        display: flex;
        flex-direction: column;
      }
      & .product-outter-list-case {
        width: 100%;
        padding: 6px 4px;
      }
    }

    & .category-masonry-case {
      overflow: hidden;
      margin: auto;
      max-width: 960px;
    }
}*/

