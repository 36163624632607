.form-inline {
    width: 100%;
  }

  .form-group {
    width: 90%;
  }

  .input-group {
    width: 90% !important;
  }

  .form-control {
    width: 100% !important;
  }

  span.input-group-addon {
    width: 50px !important;
  }
